<!--风量设置-->
<template>
  <div id="windInfo">
    <div class="windInfo-left">
      <div class="row">      
        <a-select v-model="form.status" style="width: 80px" @change="dataChange">
          <a-select-option v-for="item in form.options" :key="item.value">
            {{item.desc}}
          </a-select-option>  
        </a-select>
        <a-button style="margin-right:32px;;width: 80px" @click="setting">{{$t('lightDetails.d13')}}</a-button>
        <span > {{$t('lightDetails.d1')}}</span> 
        <span style="margin-right:16px;"> {{form.changeDatetime}}</span> 
        <span style="margin-right:16px;">{{$t('lightDetails.d2')}}{{form.factor}}</span>   
        <a-checkbox v-model= "form.maintenance" style="margin-right:16px;" @change="dataChange"> 
          {{$t('lightDetails.d3')}}
        </a-checkbox> 
      </div>      
    </div>    
  </div>
</template>

<script>
import {switchSettingMultiple} from '@/api/device';

export default {
  //父窗口传入参数
  props: {
    itemIndex: String,
    optInfo: Object,
    windStatus:'',
  },
  //本地参数
  data() {
    return {   
      form: {
              changeDatetime: '',
              factor: '',
              status: '',
              maintenance: false,
              importantDevice: false,
              voiceType: '',
              options: [
                {value:"4",desc:this.$t("airConditionDetails.a25")},
                {value:"1",desc:this.$t("airConditionDetails.a22")},
                {value:"2",desc:this.$t("airConditionDetails.a23")},
                {value:"3",desc:this.$t("airConditionDetails.a24")},                
              ],
              statusABs:[{value:"A接"},{value:"B接"}],
              statusAB:"A接",
            }, 
    };
  },   
  mounted(){
    this.setData(this.optInfo);
  },
  methods: {
    dataChange(e){ 
      console.log("dataChange:", this.form);      
      this.$emit('transferData',this.form);
    },
    setting(){
      //风量设置
      this.$emit('setting',this.form.status);
    },    
    setData(val){
        if(val!=null){
          this.form.changeDatetime=val.changeDatetime;  
          this.form.factor=val.factor;        
          this.form.status = val.status; 
          this.form.maintenance = val.maintenance;
          this.form.importantDevice=val.importantDevice; 
          this.form.voiceType=val.voiceType;     
          this.form.statusAB=val.statusAB;
        }
    },
  },
  computed: {
    pluginsDataChange () {
        return JSON.stringify(this.optInfo)
    }
  },
  watch:{
    pluginsDataChange: function (val){    
        // console.log("operateInfo data:", val);
        let oj = JSON.parse(val);   
        console.log("pluginsDataChange:", oj);  
        this.setData(oj);   
    },
  },  
};
</script>

<style scoped>
#windInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;

  border-left: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;

}
.windInfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.windInfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>