<!--温度设置-->
<template>
  <div id="tempSettingInfo">
    <div class="tempSettingInfo-left">
      <div class="row">     
        <span>{{desc}}</span>
        <!-- <span style="margin-right:16px;"> {{form.status}}℃ </span>         -->
        <a-input v-model="form.status" suffix="℃" style="width: 80px" @change="dataChange"/>        
        <a-button style="margin-right:32px;;width: 80px" @click="setting">{{$t('lightDetails.d13')}}</a-button>
        <span > {{$t('lightDetails.d1')}}</span> 
        <span style="margin-right:16px;"> {{form.changeDatetime}}</span> 
        <span style="margin-right:16px;">{{$t('lightDetails.d2')}}{{form.factor}}</span>   
        <a-checkbox v-model= "form.maintenance" style="margin-right:16px;" @change="dataChange"> 
          {{$t('lightDetails.d3')}}
        </a-checkbox> 
      </div>      
    </div>    
  </div>
</template>

<script>
import {dimmerSetting} from '@/api/device';

export default {
  //父窗口传入参数
  props: {
    itemIndex: String,
    optInfo: Object,
    temperature:String,
  },
  //本地参数
  data() {
    return {   
      form: {
              changeDatetime: '',
              factor: '',
              status: '',
              maintenance: false,
              importantDevice: false,
              voiceType: '',
              options: [{value:"开启"},{value:"关闭"}],
              statusABs:[{value:"A接"},{value:"B接"}],
              statusAB:"A接",
            }, 
      desc:this.$t('lightDetails.d11')+'：',
    };
  },   
  mounted(){
    this.setData(this.optInfo);
  },
  methods: {
    dataChange(e){ 
      console.log("dataChange:", this.form);      
      this.$emit('transferData',this.form);
    },
    setting(){
      //调光设置
      this.$emit('setting',this.form.status);    
    },
    setData(val){
        if(val!=null){
          this.form.changeDatetime=val.changeDatetime;  
          this.form.factor=val.factor;        
          this.form.status = val.status==null?"":val.status; 
          this.form.maintenance = val.maintenance;
          this.form.importantDevice=val.importantDevice; 
          this.form.voiceType=val.voiceType;     
          this.form.options=[{value:val.on},{value:val.off}]; 
          this.form.statusAB=val.statusAB;
        }
    },
  },
  computed: {
    pluginsDataChange () {
        return JSON.stringify(this.optInfo)
    }
  },
  watch:{
    pluginsDataChange: function (val){    
        // console.log("operateInfo data:", val);
        let oj = JSON.parse(val);   
        this.setData(oj);   
    },
  },  
};
</script>

<style scoped>
#tempSettingInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;

  border-left: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;

}
.tempSettingInfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.tempSettingInfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>