<template>
  <div
    id="airConditionDetails"
    v-loading="spinning"
    :element-loading-text="$t('details.a1')"
  >
      <div class="header">
        <!-- 左上角头部开关 -->
        <div class="header-header">          
          <div class="switch" @click.stop>
            <a-switch
              v-model="state"
              :loading="loading"
              @click="onClickSwitch"
            />
          </div>
          <div class="deviceName">
            <span v-show="initial">{{ deviceName }}</span>
            <!-- 名称修改 -->
            <a-icon
              type="form"
              v-show="initial"
              @click="amendName"
              :style="{ fontSize: '20px', color: '#7682CE' }"
            />
            <!-- 名称修改-输入 -->
            <a-input
              v-model="deviceName"
              v-show="alter"
              ref="input"
              :maxLength="20"
            />
            <!-- 名称修改-取消 -->
            <a-button v-show="alter" @click="amendCancel" type="primary" ghost>{{
              $t("details.a11")
            }}</a-button>
            <!-- 名称修改-确认 -->
            <a-button
              v-show="alter"
              type="primary"
              @click="updateName"
              :loading="loading"
              >{{ $t("details.a12") }}</a-button
            >
          </div>
        </div>
        <!-- 返回上一级 -->
        <a-icon
          type="close" 
          :style="{ fontSize: '20px', color: '#7682CE' }"
          @click="goBack"
        />
      </div>
      <br/>
      <div class="main">
        <div class="main-top">
          <!-- 温度设置图 -->
          <div class="main-top-left">
            <img class="image1" :src="subtract_src" @click="subtractClick" />          
            <div class="main-circle">          
                <div class="main-circle-title">
                </div>
                <div class="main-circle-value">
                  {{outVal}}
                </div>
                <div class="main-circle-status">
                  {{status}}
                </div>
            </div> 
            <img class="image1" :src="plus_src" @click="plusClick"/>                   
          </div>
          <!-- 运行模式 -->
          <div class="main-top-right">            
            <div class="image">
              <img :src="cold_src" @click="coldModeClick"/> 
              <div>{{$t("airConditionDetails.a2")}}</div>
            </div>
            <div class="image">
              <img :src="warm_src" @click="warmModeClick"/> 
              <div>{{$t("airConditionDetails.a3")}}</div>
            </div>
            <div class="image">
              <img :src="remoteCtrl_src" @click="blowingClick"/> 
              <div>{{$t("airConditionDetails.a29")}}</div>
            </div>
            <div class="image">
              <img :src="dry_src"  @click="dryClick" /> 
              <div>{{$t("airConditionDetails.a30")}}</div>
            </div>                    
          </div> 
          <br />
          <br />
          <!-- 风速 -->
          <div class="main-slider">            
            <div class="slider-desc">
              <span>{{$t("airConditionDetails.a5")}}</span>
              <!-- <a-slider class="slider" v-model="size" />              -->
              <a-radio-group class="radioGroup" v-model="winStatus" @change="onWindChange">
                <a-radio-button value="1">
                  {{$t("airConditionDetails.a22")}}
                </a-radio-button>
                <a-radio-button value="2">
                  {{$t("airConditionDetails.a23")}}
                </a-radio-button>
                <a-radio-button value="3">
                  {{$t("airConditionDetails.a24")}}
                </a-radio-button>
                <a-radio-button value="4">
                  {{$t("airConditionDetails.a25")}}
                </a-radio-button>
              </a-radio-group>
            </div> 
          </div> 
        </div> 
        <!-- 趋势曲线图 -->
        <div id="myChart" v-if="isShow" :style="{width: '100%', height: '400px'}"></div>
        <!-- 详细信息多TAB页面 -->
        <div class="main-tab">
          <a-tabs default-active-key="1" @change="callback">
            <!-- 开关设置 -->
            <a-tab-pane key="1" :tab="$t('airConditionDetails.a6')">              
              <my-title :title="title10"></my-title>      
              <my-operateInfo 
                ref="operateInfo"
                :optInfo="switchSetting.operationInfo" 
                :itemIndex="itemIndex" 
                @transferData="getTransferData"
                @setting="settingSwitch"
                ></my-operateInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="switchSetting.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane> 
            <!-- 开关状态 -->           
            <a-tab-pane key="2" :tab="$t('airConditionDetails.a7')" force-render>
              <my-title :title="title11"></my-title>
              <my-statusInfo 
                :itemIndex="itemIndex" 
                :opt-info="switchStatus.statusInfo" 
                @transferData="getTransferData">
              </my-statusInfo>              
              <my-title :title="title1"></my-title>
              <my-table :commentIds="switchStatus.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-title :title="title2"></my-title> 
              <my-tableMS :monitor="monitor" :itemIndex="itemIndex"  @transferData="getTransferMonitor"></my-tableMS>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <!-- 故障 -->
            <a-tab-pane key="3" :tab="$t('airConditionDetails.a8')">
              <my-title :title="title12"></my-title>
              <my-alertInfo :optInfo="malfunction.alertInfo" :itemIndex="itemIndex" @transferData="getTransferData"></my-alertInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="malfunction.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-title :title="title2"></my-title> 
              <my-tableMA :monitor="monitor" :itemIndex="itemIndex"  @transferData="getTransferMonitor"></my-tableMA>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <!-- 温度设置 -->
            <a-tab-pane key="4" :tab="$t('airConditionDetails.a9')">
              <my-title :title="title13"></my-title>      
              <my-tempSettingInfo 
                ref="myTempSettingInfo" 
                :optInfo="temperatureSetting.measureInfo" 
                :itemIndex="itemIndex" 
                @transferData="getTransferData"
                @setting="settingTemp"
              ></my-tempSettingInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="temperatureSetting.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <!-- 吸入温度 -->
            <a-tab-pane key="5" :tab="$t('airConditionDetails.a10')">              
              <my-title :title="title14"></my-title>      
              <my-tempStautsInfo :optInfo="temperatureStatus.measureInfo" :itemIndex="itemIndex" @transferData="getTransferData"></my-tempStautsInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="temperatureStatus.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <!-- 运转模式 -->
            <a-tab-pane key="6" :tab="$t('airConditionDetails.a11')">
              <my-title :title="title13"></my-title>      
              <my-runModeInfo 
              :optInfo="runMode.measureInfo" 
              :itemIndex="itemIndex" 
              @transferData="getTransferData"
              @setting="settingRunMode"
              ></my-runModeInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="runMode.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <!-- 风量设置 -->
            <a-tab-pane key="7" :tab="$t('airConditionDetails.a12')">
              <my-title :title="title13"></my-title>      
              <my-windInfo 
              :optInfo="wind.measureInfo" 
              :itemIndex="itemIndex" 
              @transferData="getTransferData"
              @setting="settingWind"
              ></my-windInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="wind.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <!-- 火灾信号 -->
            <a-tab-pane key="8" :tab="$t('airConditionDetails.a28')" force-render>
              <my-title :title="title10"></my-title>      
              <my-remoteCtrlInfo 
                :optInfo="remoteCtrl.operationInfo" 
                :itemIndex="itemIndex" 
                @transferData="getTransferData"
                @setting="settingRemoteCtrl"
              >
              </my-remoteCtrlInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="remoteCtrl.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
          </a-tabs>
        </div>                
      </div> 
      <div class="footer">
        <a-button type="primary" @click="onSetting">{{this.$t('lightDetails.d13')}}</a-button>
      </div>             
  </div>    
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import lightTable from "../lightDetails/components/lightTableC";
import lightTableMS from "../lightDetails/components/lightTableMS";
import lightTableMA from "../lightDetails/components/lightTableMA";
import lightTableB from "../lightDetails/components/lightTableB";
import alertInfo from "../lightDetails/components/alertInfo";
import statusInfo from "../lightDetails/components/statusInfo";
import operateInfo from "../lightDetails/components/operateInfo";
import tempSettingInfo from "./components/tempSettingInfo";
import tempStautsInfo from "./components/tempStautsInfo";
import runModeInfo from "./components/runModeInfo";
import windInfo from "./components/windInfo";
import remoteCtrlInfo from "./components/remoteCtrlInfo";

import scheduleCtrl from "../lightDetails/components/scheduleCtrl";
import {switchSettingMultiple,
  switchSetting,
 dimmerSetting, 
 getAirConditionDetails,
 updateAnalogInputObj,
 updateAnalogOutputObj,
 updateBinaryData,
 updateMultipleData,
 updateDeviceName } from "../../api/device";
import substract_disable_img from '../../../public/images/air/air36.png'
import substract_off_img from '../../../public/images/air/air37.png'
import substract_on_img from '../../../public/images/air/air38.png'
import plus_disable_img from '../../../public/images/air/air39.png'
import plus_off_img from '../../../public/images/air/air40.png'
import plus_on_img from '../../../public/images/air/air41.png'
import remoteCtrl_disable_img from '../../../public/images/air/air21.png'
import remoteCtrl_off_img from '../../../public/images/air/air22.png'
import remoteCtrl_on_img from '../../../public/images/air/air23.png'
import cold_disable_img from '../../../public/images/air/air24.png'
import cold_off_img from '../../../public/images/air/air25.png'
import cold_on_img from '../../../public/images/air/air26.png'
import warm_disable_img from '../../../public/images/air/air27.png'
import warm_off_img from '../../../public/images/air/air28.png'
import warm_on_img from '../../../public/images/air/air29.png'
import dry_off_img from '../../../public/images/air/air34.png'
import dry_on_img from '../../../public/images/air/air35.png'
import economy_disable_img from '../../../public/images/air/air30.png'
import economy_off_img from '../../../public/images/air/air31.png'
import economy_on_img from '../../../public/images/air/air32.png'

import eventBus from '../../common/eventBus.js';//参数传递空模块

export default {
  data() {
    return {     
      initial: true,
      alter: false,
      initialname: "",
      subtract_src: substract_on_img,
      plus_src: plus_on_img,
      remoteCtrl_src: remoteCtrl_off_img,
      cold_src: cold_off_img,
      warm_src: warm_off_img,
      dry_src:dry_off_img,
      economy_src: economy_off_img,
      isShow:false,
      signal: "", 
      tagPoint:"",
      time:1000,//延迟时间（单位毫秒）
      timeRefresh:2000,//等待刷新时间
      spinning: false,
      loading: false,
      changeState: false,
      scheduleCtrl: false,
      title0: this.$t('airConditionDetails.a14'),      
      title1: this.$t('airConditionDetails.a15'),
      title2: this.$t('airConditionDetails.a16'),
      title10: this.$t('lightDetails.d8'),      
      title11: this.$t('lightDetails.d9'), 
      title12: this.$t('lightDetails.d10'), 
      title13: this.$t('lightDetails.d11'), 
      title14: this.$t('lightDetails.d12'),
      outVal: "",
      outValMax:30.0,
      outValMin:0.0,
      inVal: "",
      comment:["","","","",""],
      deviceName: this.$t('airConditionDetails.a17'),      
      remark:{},
      trend:{},
      monitor:{},  
      optInfo:{},    
      temperatureSetting:{},//温度设置
      temperatureStatus:{},  //吸入温度
      switchSetting:{},//开关设置
      switchStatus:{},//开关状态
      malfunction:{},//故障
      runMode:{},//运行模式
      wind:{},//风量
      remoteCtrl:{},//遥控禁止温度开关设置
      itemIndex:"",
      size: 6,
      selectKey:"1",
      signalNo:0,
      state:false,//实际状态
      status:'',//新状态
      winStatus:'0',
      runModeStatus:'0',
      options: [{value:this.$t('airConditionDetails.a18')},{value:this.$t('airConditionDetails.a26')}],
    };
  },
  computed: {
    // state:function(){
    //   if(this.optInfo!=null&&this.optInfo.status==this.$t('airConditionDetails.a18')){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // }
  },
  mounted() {
    this.getData();
  },
  watch: { 
    runModeStatus(val){
      switch (val) {
        case "1":
          this.cold_src = cold_off_img;
          this.warm_src = warm_on_img;
          this.remoteCtrl_src=remoteCtrl_off_img;
          this.dry_src=dry_off_img;
          break;
        case "2":
          this.cold_src = cold_on_img;
          this.warm_src = warm_off_img;
          this.remoteCtrl_src=remoteCtrl_off_img;
          this.dry_src=dry_off_img;
          break;
        case "3":
          this.cold_src = cold_off_img;
          this.warm_src = warm_off_img;
          this.remoteCtrl_src=remoteCtrl_on_img;
          this.dry_src=dry_off_img;
          break;
        case "4":
          this.cold_src = cold_off_img;
          this.warm_src = warm_off_img;
          this.remoteCtrl_src=remoteCtrl_off_img;
          this.dry_src=dry_on_img;
          break;
        default:
          this.cold_src = cold_off_img;
          this.warm_src = warm_off_img;
          this.remoteCtrl_src=remoteCtrl_off_img;
          this.dry_src=dry_off_img;
          break;
      } 
    },   
    selectKey (val, oldVal) {
      if (val) {
        if(this.isShow){
          this.sleep(this.time).then(()=>{         
            this.drawLine(this.trend, this.signal,this.tagPoint);            
          }) 
        }       
      }
    },
  },
  methods: {  
    // 修改名称时切换输入框的方法
    amendName() {
      this.initialname = this.deviceName;
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.deviceName = this.initialname;
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
    // 修改名称的方法
    updateName() {
      this.loading = true;
      let par = {
          id:this.$route.query.id,//ID
          name: this.deviceName,
      };
      console.log("updateDeviceName par:", par);
      updateDeviceName(par)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.errorCode == "00") {
            this.initial = !this.initial;
            this.alter = !this.alter;
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
            this.amendCancel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //页面开关操作点击
    onClickSwitch(checked){
      this.state = checked;
      if(checked){  
        this.status=this.$t('airConditionDetails.a18');        
      }
      else
        this.status=this.$t('airConditionDetails.a26'); 
      this.switchSet();
    },
    //开关设置
    switchSet() {       
      this.loading = true;  
      let par = {
          signalId:this.switchSetting.signalId,//ID
          operationInfo: {
            ...this.switchSetting.operationInfo, // operationInfo字段(对象)
            id:this.switchSetting.signalId,//ID
            status: this.status,
            on:this.options[0].value,
            off:this.options[1].value,
          },//信息内容
          statusInfo:{
            id:this.switchStatus.signalId,//ID
          }
      };
      console.log('this.switchSetting:', this.switchSetting, this.switchStatus);
      console.log("switchSet par:", par);     
      switchSetting(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("switchSet result", data);
          if(data){
            if(this.switchSetting!=null&&this.switchSetting.operationInfo!=null)
              this.switchSetting.operationInfo.status=this.status;//返回成功更新状态
            if(this.switchStatus!=null&&this.switchStatus.statusInfo!=null){
              this.switchStatus.statusInfo.status=this.status;
              console.log("switchSet status", this.switchStatus.statusInfo.status);
            }
            this.$message.info(this.$t('lightDetails.d14'));            
          }
          else{
            this.state = !this.state;
            if(this.switchSetting!=null&&this.switchSetting.operationInfo!=null)
              this.status=this.switchSetting.operationInfo.status;//恢复旧状态
            if(this.switchStatus!=null&&this.switchStatus.statusInfo!=null&&this.switchSetting!=null&&this.switchSetting.operationInfo!=null){
              this.switchStatus.statusInfo.status=this.switchSetting.operationInfo.status
            }
            this.$message.error(this.$t('lightDetails.d15')); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },

    //控件开关设置回调
    settingSwitch(status){
      console.log("settingSwitch status:", status);
      this.state = status==this.$t('airConditionDetails.a18')?true:false;
      this.status=status;
      this.switchStatus.statusInfo.status=status;
      this.switchSet();         
    },
    
    refreshTemp(msg){
      console.log("refreshTemp msg:", msg);
      this.outVal=msg.status;
      this.temperatureSetting.outVal=this.outVal;
      this.temperatureSetting.measureInfo.status = this.outVal;

    },
    
    
    refresh1(msg){
      console.log("refresh msg:", msg);
      this.sleep(this.timeRefresh).then(()=>{         
            this.getData();            
          })
    },
    sleep(ms) {
      return new Promise(resolve => 
        setTimeout(resolve, ms)
       )
    },
    onWindChange(e){
      this.windSetting();
    },
    
    //风量模式设置
    windSetting() {       
      this.loading = true;  
      let par = {
          signalId:this.wind.signalId,//ID
          measureInfo: {status:this.winStatus}//信息内容
      };
      console.log("windSetting par:", par);     
      switchSettingMultiple(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          if(data){            
            this.wind.measureInfo.status = this.winStatus;
            this.$message.info(this.$t('lightDetails.d14')); 
          }
          else{
            this.winStatus=this.wind.measureInfo.status;
            this.$message.error(this.$t('lightDetails.d15'));
          }
          console.log("windSetting result", data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },

    settingWind(msg){
      this.winStatus=msg;
      this.windSetting();
    },
    //冷风
    coldModeClick(){
      if(this.runModeStatus!="2"){
          this.runModeStatus= "2";
      }
      this.runModeSetting();
    },
    //暖风
    warmModeClick(){
      if(this.runModeStatus!="1"){
          this.runModeStatus= "1";
      }
      this.runModeSetting();
    },
    //送风
    blowingClick(){
      if(this.runModeStatus!="3"){
          this.runModeStatus= "3";
      }
      this.runModeSetting();   
    },
    //抽湿
    dryClick(){
      if(this.runModeStatus!="4"){
          this.runModeStatus= "4";
      }
      this.runModeSetting();   
    },    

    //运转模式设置
    runModeSetting() {       
      this.loading = true;  
      let par = {
          signalId:this.runMode.signalId,//ID
          measureInfo: {status:this.runModeStatus}//信息内容
      };
      console.log("runModeSetting par:", par);     
      switchSettingMultiple(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          if(data){            
            this.runMode.measureInfo.status = this.runModeStatus;
            this.$message.info(this.$t('lightDetails.d14')); 
          }
          else{
            this.runModeStatus = this.runMode.measureInfo.status;
            this.$message.error(this.$t('lightDetails.d15'));
          }
          console.log("runModeSetting result", data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },
    settingRunMode(status){
      this.runModeStatus=status;
      this.runModeSetting();
    },

    
    //遥控开关设置
    remoteCtrlSetting(status) {       
      this.loading = true;  
      let par = {
          signalId:this.remoteCtrl.signalId,//ID
          operationInfo: {
            status:status,
            on:"全运行",
            off:"全停止",
          }//信息内容
      };
      console.log("remoteCtrlSetting par:", par);     
      switchSetting(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("remoteCtrlSetting result", data);
          if(data){
            this.$message.info(this.$t('lightDetails.d14'));
            if(this.remoteCtrl!=null&&this.remoteCtrl.operationInfo!=null)
              this.remoteCtrl.operationInfo.status=status;
            // if(remoteCtrl_on_img==this.remoteCtrl_src)
            //   this.remoteCtrl_src = remoteCtrl_off_img;
            // else
            //   this.remoteCtrl_src = remoteCtrl_on_img;
          }
          else{
            this.$message.error(this.$t('lightDetails.d15')); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    settingRemoteCtrl(status){
      this.remoteCtrlSetting(status);
    },
    
    //加号点击函数
    plusClick(){
      if(this.loading ||this.plus_src==plus_disable_img)
        return;
      var temp = parseInt(this.outVal);
      this.outVal = (temp+1).toString();
      // this.temperatureSetting.outVal=this.outVal;
      // this.temperatureSetting.measureInfo.status = this.outVal;
      this.tempSetting();      
      // this.$nextTick(()=>{
      //   this.$refs.myTempSettingInfo.setting();//调用组件方法
      // })      
    },
    //减号点击函数
    subtractClick(){
      if(this.loading||this.subtract_src==substract_disable_img)
        return;
      var temp = parseInt(this.outVal);
      this.outVal = (temp-1).toString();      
      // this.temperatureSetting.outVal=this.outVal;
      // this.temperatureSetting.measureInfo.status = this.outVal;
      // this.$refs.my-tempSettingInfo.setting();//调用组件方法
      this.tempSetting();
    },

    changeImage(){
      if(parseFloat(this.outVal)<=this.outValMin){
        this.subtract_src=substract_disable_img;
      } 
      else if(parseFloat(this.outVal)>=this.outValMax){
        this.plus_src= plus_disable_img;
      }
      else{
        this.subtract_src=substract_on_img;
        this.plus_src= plus_on_img;
      }
    },

    tempSetting() {       
      this.loading = true;  
      let par = {
          signalId:this.temperatureSetting.signalId,//ID
          measureInfo: {status:this.outVal}
      };
      console.log("tempSetting par:", par);     
      dimmerSetting(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          
          if(data){        
            console.log("dimmerSetting temperatureSetting.outVal", this.temperatureSetting.outVal);    
            console.log("dimmerSetting outVal", this.outVal);
            this.temperatureSetting.outVal=this.outVal;
            this.temperatureSetting.measureInfo.status=this.outVal;
            this.$message.info(this.$t('lightDetails.d14')); 
          }
          else{
            this.outVal=this.temperatureSetting.measureInfo.status;
            this.$message.error(this.$t('lightDetails.d15')); 
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
        //恢复加减图片状态
        this.changeImage();
    },

    settingTemp(temp){
      this.outVal=temp;
      this.tempSetting();
    },

    getTransferMonitor(msg){
      console.log("子组件传参内容 info:",msg);
      this.monitor = msg;
    },
    getCommentData(msg){
      console.log("子组件传参内容 comment:",msg);
      this.comment = msg;
    },
    getTransferData(msg){
      console.log("子组件传参内容 info:",msg);
      this.optInfo = msg;
    },
    //设置
    onSetting(){
      console.log("onSetting selectKey:", this.selectKey); 
      switch(this.selectKey){
        case "1"://开关设置
          this.updateBinaryData(this.switchSetting.signalId,2);
          break; 
        case "2"://开关状态
          this.updateBinaryData(this.switchStatus.signalId,1);
          break; 
        case "3"://故障
          this.updateBinaryData(this.malfunction.signalId,4);
          break; 
        case "8"://火灾信号
          this.updateBinaryData(this.remoteCtrl.signalId,2);
          break;        
        case "4"://温度设置
          this.temperatureDetailSetting(this.temperatureSetting.signalId);
          break;
        case "5"://吸入温度
          this.dimmerStatusSetting(this.temperatureStatus.signalId);
          break;
        case "6"://运转模式设置
          this.updateMultipleData(this.runMode.signalId);
          break;
        case "7"://风量设置
          this.updateMultipleData(this.wind.signalId);
          break;
      }
    },   
    updateMultipleData(signalId){
      this.loading = true;  
      let par = {
        signalId:signalId,
        signalNo:this.signalNo,
        pointType:2,
        measureInfo:this.optInfo,
        comment:this.comment,
      }; 
      console.log("updateMultipleData par:", par);     
      updateMultipleData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateMultipleData result", data);
          this.$message.info(this.$t('lightDetails.d14')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        });
    },
    updateBinaryData(signalId,pointType){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        signalNo:this.signalNo,
        pointType:pointType,
        operationInfo:this.optInfo,
        statusInfo:this.optInfo,
        alertInfo:this.optInfo,
        comment:this.comment,
        monitor:this.monitor,
      }; 
      console.log("updateBinaryData par:", par);     
      updateBinaryData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateBinaryData result", data);
          if(data!=null &&data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },
    temperatureDetailSetting(signalId){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        measureInfo:this.optInfo,
        comment:this.comment,
      }; 
      console.log("updateAnalogOutputObj par:", par);     
      updateAnalogOutputObj(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateAnalogOutputObj result", data);
          if(data!=null &&data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    dimmerStatusSetting(signalId){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        measureInfo:this.optInfo,
        comment:this.comment,
      }; 
      console.log("updateAnalogInputObj par:", par);     
      updateAnalogInputObj(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateAnalogInputObj result", data);
          if(data!=null &&data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    callback(key) {
      this.selectKey=key;
      this.refreshData(key); 
        
    },
    // 页面后退
    goBack() {
      let airConditionStatus={
        id:this.$route.query.id,
        tempOut:this.temperatureSetting.outVal,
        tempIn:this.temperatureStatus.inVal,
        switchStatus:this.status==this.switchStatus.statusInfo.on?true:false,
        mode:this.runMode.measureInfo.status,
        wind:this.wind.measureInfo.status,
      };
      //传递一个map，airConditionStatus是key，airConditionStatus是value
      eventBus.$emit('airConditionStatus',airConditionStatus);
      //调用router回退页面
      this.$router.go(-1);
    },
    
    getData() {  
      this.spinning = true;  
      let id = this.$route.query.id;
      let siteId = this.$route.query.siteId;
      let analogPoints = this.$route.query.analogPoints;
      let digitalPoints = this.$route.query.digitalPoints;
      let mutiPoints=this.$route.query.mutiPoints;
      console.log("getAirConditionDetails analogPoints:",analogPoints);
      console.log("getAirConditionDetails digitalPoints:",digitalPoints);
      console.log("getAirConditionDetails mutiPoints:",mutiPoints);
      getAirConditionDetails({id, siteId,analogPoints,digitalPoints,mutiPoints})
        .then((res) => {
          let data = res.data;  
          console.log("getAirConditionDetails data:",data);
          if(data!=null){                  
            this.switchSetting = data.switchSetting; 
            this.switchStatus = data.switchStatus;                
            //特别处理，将状态信号开关状态改为与开关信号一致
            if(this.switchSetting!=null&&this.switchSetting.operationInfo!=null &&this.switchStatus!=null&& this.switchStatus.statusInfo!=null)
              this.status=this.switchStatus.statusInfo.status ;
            // this.state = this.status==this.$t('airConditionDetails.a18')?true:false;
            this.state = this.status==this.switchStatus.statusInfo.on?true:false;
            this.malfunction = data.malfunction;
            this.temperatureSetting = data.temperatureSetting;
            this.temperatureStatus = data.temperatureStatus;
            this.runMode = data.runMode;
            this.runModeStatus=this.runMode.measureInfo.status;
            this.wind = data.wind;
            this.winStatus=this.wind.measureInfo.status;
            this.remoteCtrl = data.remoteCtrl; 
            
            this.refreshData("1");   
          } 
          this.spinning = false;          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err)
        });
    },
    chartResize(){
        try{
            let o = document.getElementById("myChart");
            if(o!=null){
                this.$echarts.init(document.getElementById("myChart")).resize();
            }
        }catch(e){
            console.log(e);
        } 
    },
    drawLine(trend,signal,tag) {
      // 基于准备好的dom，初始化echarts实例
      console.log("drawLine signal",signal);
      console.log("drawLine trend",trend);     

      let myChart = this.$echarts.init(document.getElementById("myChart"));
      let trendData = trend.data;
      let categories = trend.categories;
      let unit = trend.yunit+"/"+trend.xunit;
      // let tag = this.temperatureStatus.tagPoint;
      let sname = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
      let axisLabel ={};

      switch(this.selectKey){         
        case "2"://开关状态
          axisLabel={
                    formatter: function (value) {
                      var texts = [];
                	    switch(value){
                        case 1:
                          texts.push('运行');
                          break;
                        case 0:
                	        texts.push('停止');
                          break;
                	    } 
                      return texts;               	    
                    }
                };
          break;         
        case "6"://运转模式设置
          axisLabel={
                    formatter: function (value) {
                      var texts = [];
                	    switch(value){
                        case 0:
                          // texts.push('未选');
                          break;
                        case 1:
                	        texts.push('制热');
                          break;
                        case 2:
                          texts.push('制冷');
                          break;
                        case 3:
                	        texts.push('送风');
                          break;
                        case 4:
                	        texts.push('抽湿');
                          break;
                	    } 
                      return texts;               	    
                    }
                };
          break;
        case "7"://风量设置
          axisLabel={
                    formatter: function (value) {
                      var texts = [];
                	    switch(value){
                        case 0:
                          // texts.push('未选');
                          break;
                        case 1:
                	        texts.push('弱风');
                          break;
                        case 2:
                          texts.push('中风');
                          break;
                        case 3:
                	        texts.push('强风');
                          break;
                	    } 
                      return texts;               	    
                    }
                };
          break;
        case "1"://开关设置
        case "3"://故障
        case "8"://火灾信号       
        case "4"://温度设置
        case "5"://吸入温度
          axisLabel={formatter:'{value}'};
          break;
      }

      // 绘制图表
      let option = {
        title: {
          text: sname,
          subtext: trend.yunit,
          left: "6%",
          textStyle: {
            fontSize: 18,
            color: "#000",
            fontWeight: "bold"
          }
        },
        tooltip: {
          trigger: "axis",
           subtext: trend.yunit,
        },
        // x轴配置
        xAxis: {
          type: "category",
          data: categories
        },
        // y轴配置
        yAxis: {
          type: "value",
          show: true,    // 是否显示
          axisLabel:axisLabel,
        },
        series: [
          {
            name: signal,
            data: trendData,
            type: "line",
            smooth: true
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    refreshData(key) {        
      this.itemIndex = key;  
      this.deviceName = this.$route.query.deviceName;
      this.outVal=this.temperatureSetting.outVal;
      this.inVal=this.temperatureStatus.inVal;  
      // console.log("refreshData runMode:",this.runMode);     
      // if(this.runMode.outVal == this.$t('airConditionDetails.a19')){
      //   this.cold_src = cold_on_img;
      // }
      // else if(this.runMode.outVal == this.$t('airConditionDetails.a20')){
      //   this.warm_src = warm_on_img;
      // } 
      // else if(this.runMode.outVal==this.$t('airConditionDetails.a29')){
      //   this.remoteCtrl_src=remoteCtrl_on_img;
      // }
      if(this.malfunction!=null) {      
        if(this.malfunction.inVal!=null){
           if(this.malfunction.inVal=="0")
              this.malfunction = this.$t('airConditionDetails.a21') ;  
           else 
              this.malfunction = this.$t('airConditionDetails.a8'); 
        }
      } 
      this.isShow = false;//是否显示曲线图 
      switch(key){        
        case "1":  
          if(this.switchSetting!=null){                
            if(this.switchSetting.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.switchSetting.comment;
            }            
            this.signalNo = this.switchSetting.signalNo;            
            this.scheduleCtrl= this.switchSetting.scheduleCtrl==1?true:false;          
            this.remark = this.switchSetting.remark;          
            this.monitor = this.switchSetting.monitor;  
            // if(this.switchSetting.operationInfo!=null)
            //   this.status=this.switchSetting.operationInfo.status;  
          } 
          console.log("传参内容1 switchSetting:",this.switchSetting);
          break;
        case "2":  
          this.isShow=true;  
          if(this.switchStatus!=null){
            this.trend=this.switchStatus.trendDatas;
            if(this.switchStatus.signalType!=null)
              this.signal = this.switchStatus.signalType;
            if(this.switchStatus.tagPoint!=null)
              this.tagPoint=this.switchStatus.tagPoint;              
            if(this.switchStatus.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.switchStatus.comment;
            }
            if(this.switchStatus.statusInfo!=null)
              this.status=this.switchStatus.statusInfo.status; 
            this.signalNo = this.switchStatus.signalNo;
            this.scheduleCtrl= this.switchStatus.scheduleCtrl==1?true:false;          
            this.remark = this.switchStatus.remark;            
            this.monitor = this.switchStatus.monitor;
          }
          console.log("传参内容2 switchStatus:",this.switchStatus);
          break;
        case "3":       
          if(this.malfunction!=null){   
            if(this.malfunction.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.malfunction.comment;
            }
            
            this.signalNo = this.malfunction.signalNo;
            this.optInfo = this.malfunction.alertInfo;
            this.scheduleCtrl= this.malfunction.scheduleCtrl==1?true:false;          
            this.remark = this.malfunction.remark;            
            this.monitor = this.malfunction.monitor;  
          }      
          console.log("传参内容3 malfunction:",this.malfunction);  
          break;
        case "4":     
          this.isShow=true;  
          if(this.temperatureSetting!=null){
            this.trend=this.temperatureSetting.trendDatas;      
            if(this.temperatureSetting.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.temperatureSetting.comment;
            }     
            
            this.signalNo = this.temperatureSetting.signalNo;
            this.remark = this.temperatureSetting.remark;
          }
          console.log("传参内容4 temperatureSetting:",this.temperatureSetting);          
          break;
        case "5":         
          this.isShow=true;  
          if(this.temperatureStatus!=null){
            this.trend=this.temperatureStatus.trendDatas; 
            if(this.temperatureStatus.signalType!=null)
              this.signal = this.temperatureStatus.signalType;
            if(this.temperatureStatus.tagPoint!=null)
              this.tagPoint=this.temperatureStatus.tagPoint;   
            
            if(this.temperatureStatus.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.temperatureStatus.comment;
            }   
            
            this.signalNo = this.temperatureStatus.signalNo;
            this.remark = this.temperatureStatus.remark;     
          }    
                  
          console.log("传参内容5 temperatureStatus:",this.temperatureStatus);
          break;
        case "6":     
          this.isShow=true;  
          if(this.runMode!=null){
            this.trend=this.runMode.trendDatas;
            if(this.runMode.signalType!=null)
              this.signal = this.runMode.signalType;
            if(this.runMode.tagPoint!=null)
              this.tagPoint=this.runMode.tagPoint;      
            if(this.runMode.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.runMode.comment;
            }        
            
            this.signalNo = this.runMode.signalNo;
            this.remark = this.runMode.remark;
          }
          console.log("传参内容6 runMode:",this.runMode);
          break;
        case "7":    
          this.isShow=true;  
          if(this.wind!=null){
            this.trend=this.wind.trendDatas;
            if(this.wind.signalType!=null)
              this.signal = this.wind.signalType;
            if(this.wind.tagPoint!=null)
              this.tagPoint=this.wind.tagPoint;       
            if(this.wind.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.wind.comment;
            }              
            this.signalNo = this.wind.signalNo;
            this.remark = this.wind.remark;
          }
          console.log("传参内容7 wind:",this.wind);
          break;
        case "8":     
          if(this.remoteCtrl!=null){     
            if(this.remoteCtrl.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.remoteCtrl.comment;
            }
            
            this.signalNo = this.remoteCtrl.signalNo;
            
            this.scheduleCtrl= this.remoteCtrl.scheduleCtrl==1?true:false;          
            this.remark = this.remoteCtrl.remark;          
            this.monitor = this.remoteCtrl.monitor; 
          }  
          console.log("传参内容8 remoteCtrl:",this.remoteCtrl);       
          break;
      } 
      window.addEventListener("resize", this.chartResize());       
    }
  },
  destroyed() {
    console.log("destroyed");
    window.removeEventListener('resize',this.chartResize());
  },
  components: {
    "my-title": tabletitle,
    "my-table": lightTable,
    "my-tableMS": lightTableMS,
    "my-tableMA": lightTableMA,
    "my-tableB": lightTableB,
    "my-scheduleCtrl": scheduleCtrl,
    "my-operateInfo": operateInfo, 
    "my-statusInfo": statusInfo,   
    "my-alertInfo":alertInfo,
    "my-tempSettingInfo": tempSettingInfo,   
    "my-tempStautsInfo":tempStautsInfo,
    "my-runModeInfo":runModeInfo,
    "my-windInfo":windInfo,
    "my-remoteCtrlInfo":remoteCtrlInfo,
    
  },
};
</script>

<style scoped>
#airConditionDetails {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.header-header span {
  margin-right: 30px;
}
.switch {
  display: flex;
  align-items: center;
}
.deviceName{
  margin-left: 20px;
}
.deviceName span {
  /* color: #898989; */
  white-space: nowrap;
  margin-right: 20px;
}
.deviceName input {
  margin-right: 10px;
}
.deviceName button {
  margin-right: 10px;
}
.main {  
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* margin: 0 auto; */
}
.main-top{    
  width: 100%;  
  margin: 20;
  height:200px;
}
.main-top-left {
  display: flex;
  justify-content:flex-end;
  align-items:center;  
  width:49%;
  margin: 20;
  float:left;  
  padding-left:20px;
}
.main-top-right{  
  width:49%;
  height:49%;
  display: flex;
  justify-content:flex-start;
  align-items:center;    
  float:left;
  padding-top:30px;
  padding-left:60px;
}
.main-slider{
  width:49%;
  height:49%;
  display: flex;
  justify-content:flex-start;
  align-items:center; 
}
.radioGroup{
  margin-left: 10px;
}
.image{  
  flex:none;
  width:60px;
  margin:10px;
  font-size:13px;
  text-align:center;
  float:left;
}
.main-circle {
  padding:20px;
  width:180px;
  height:180px;
  border-radius: 50%;
  border:1px solid green;   
  text-align:center;
}
.image1{  
  margin:20px;
}
.main-circle-title {
  font-size:13px;
  color:#898989;
  margin-top:20%;
}
.main-circle-value {
  font-size:35px;
}
.main-circle-status {
  font-size:13px;
  color:red;
  margin-bottom:30%;
}
.main-tab{
  width:100%;
  float:left;
}

.slider-desc{
  padding-left:80px;
}
.slider{
  width:30%;
  float:left;
  /* padding-left:100px; */
}
.footer {
  height: 55px;
  width:100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 20px 20px 20px;
}
</style>